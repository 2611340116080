import moment from "moment";
import axios from "axios";
import _ from "lodash";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { PaginationType } from "components/search-bar/enums.utils";
import {
  SEARCH_CATEGORIES,
  FILTERS,
} from "pages/partview/components/search/PartView.searchOptions";

const STORE_MOUNT_POINT = "partViewSearch";

// SearchBarStateBuilder need 'solutionId' as first parameter,
// but not required here therefore named it _ignored_solutionId
const entitiesUrl = (_ignored_solutionId, queryString) => {
  return apiUrl(`/partview/app/opensearch/search?status=active&${queryString}`);
};

const batchSearchUrl = (queryString, batchType) => {
  return apiUrl(
    `/partview/app/search?status=active&${queryString}&batchType=${batchType}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;",
    },
  };
};

const fetchSearch = (
  queryString = "",
  _ignored_solutionId,
  duck,
  dispatch,
  state,
) => {
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter) {
    // Batch search POST
    batchSearch(queryString, dispatch, batchFilter, duck);
  } else {
    const url = entitiesUrl(null, queryString);
    const config = axiosConfig();

    // Fetch the search
    dispatch(duck.fetch(url, config));

    // Redirect to PartView search page
    dispatch({ type: "PARTVIEW_SEARCH" });
  }
};

const batchSearch = (queryString, dispatch, batchFilter, duck) => {
  const batchValueArray = batchFilter.batch_list.split(",");
  const batchType = batchFilter.batch_type;
  const url = batchSearchUrl(queryString, batchType);
  const data = {
    batchList: batchValueArray,
  };
  const config = axiosConfig();

  dispatch({
    type: duck.actions.REQUEST,
  });

  axios
    .post(url, data, config)
    .then((response) => {
      dispatch({
        type: duck.actions.RECEIVE,
        payload: response.data ? response.data : [],
      });

      dispatch({ type: "PARTVIEW_SEARCH" });
    })
    .catch((err) => {
      dispatch({
        type: duck.actions.REQUEST_ERROR,
        err,
      });
    });
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  { defaultSort: "destinationEta", reverseSort: false },
  20,
  PaginationType.OPEN_SEARCH,
);

SearchBarState.actionCreators.exportSearch = _.partial(
  SearchBarState.actionCreators.exportEntities,
  entitiesUrl,
  null,
  { headers: { Accept: "text/csv;version=full" } },
  "part-view-search-results",
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
};

export default SearchBarState;
