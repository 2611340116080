import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";
import { VictoryTooltip } from "victory";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { PieChartWithLegends } from "components/molecules/PieChartWithLegends.molecule";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";
import { FontSize, Text } from "components/atoms/Text.atom";
import Colors from "styles/colors";

const CustomTooltip = (props: any) => {
  let { count, totalPackages, fill, name } = props.datum;

  const { t } = useTranslation("partview-dashboard");

  const calculatePercentage = () => {
    return ((count / totalPackages) * 100).toFixed(1);
  };

  const reSizeFont = () => {
    return count > 100000 ? true : false;
  };

  return (
    <foreignObject x={120} y={20} width="165" height="100">
      <div
        style={{
          backgroundColor: "white",
          boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2),0px 0px 3px 1px ${fill} inset`,
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "1rem",
        }}
      >
        <Text css={{ color: "#8b8b8b", fontSize: "7px" }}>
          {t("partview-dashboard:All Packages")}
        </Text>
        <Text bold css={{ fontSize: "10px", marginBottom: "0.2rem" }}>
          {t(`partview-dashboard:Package ${name} Status`)}
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "0.2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#EFF2F4",
              padding: "1px",
              borderRadius: "2px",
              width: "100%",
            }}
          >
            <Text
              bold
              size={reSizeFont() ? FontSize.size12 : FontSize.size13}
              color="#FF8235"
            >
              <LocalizedNumber value={count} />
            </Text>
            <Text block css={{ fontSize: "7px" }}>
              {t("partview-dashboard:Number of Packages")}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#EFF2F4",
              padding: "1px",
              borderRadius: "2px",
              width: "100%",
            }}
          >
            <Text bold size={FontSize.size13} color="#FF8235">
              {calculatePercentage()}%
            </Text>
            <Text block css={{ fontSize: "7px" }}>
              {t("partview-dashboard:Total % of the packages")}
            </Text>
          </div>
        </div>
      </div>
    </foreignObject>
  );
};

const titleStyles = [
  {
    fontSize: 10,
    fill: Colors.text.TOTALS_LABEL_GRAY,
  },
  {
    fontSize: 12,
    fontWeight: 600,
    fill: Colors.text.DARK_GRAY,
  },
  {
    fontSize: 2,
    fontWeight: 600,
    fill: Colors.text.WHITE,
  },
];

const partViewStatusWidgetLabelStyle = {
  labels: { fontSize: 10 },
};

const widgetLabelsWidthHeight = { width: 160, height: 160 };

type WIDGETDATA = {
  key: string;
  type: string;
  count: number;
  name: string;
  searchOption: string;
  fill: string;
  label: string;
};

type PackageCountStatusType = {
  headerTitle: string;
  data: WIDGETDATA[];
  isLoading?: boolean;
  subTitle: string;
  title: string;
  handleCountClick: () => {};
  queryKey: string;
};

export const PackageStatusCount = ({
  headerTitle,
  data,
  isLoading,
  subTitle,
  title,
  handleCountClick,
  queryKey,
}: PackageCountStatusType) => {
  return (
    <PanelGroup>
      <PanelGroup.Header title={headerTitle} />
      <div style={{ minHeight: "10rem" }}>
        {isLoading ? (
          <Loader loaded={false} />
        ) : (
          <PieChartWithLegends
            widgetData={data}
            subTitle={subTitle}
            title={title}
            x={"type"}
            y={"count"}
            onClickHandler={handleCountClick}
            queryKey={queryKey}
            alignWidgetToLeft={true}
            innerRadius={35}
            titleStyles={titleStyles}
            labelStyles={partViewStatusWidgetLabelStyle}
            widgetLabelsWidthHeight={widgetLabelsWidthHeight}
            labelComponent={
              <VictoryTooltip
                flyoutComponent={<CustomTooltip />}
                style={{
                  display: "none",
                }}
              />
            }
          />
        )}
      </div>
    </PanelGroup>
  );
};
