import { createSelector } from "reselect";

import VinViewDomainDataState from "modules/domain-data/VinViewDomainData";
import { createOptionsMapper } from "utils/selectors-utils";

// Helpers
const valuesMapper = createOptionsMapper({ sorted: false });

// Selectors
export const selectCarrierFilterOptions = createSelector(
  [VinViewDomainDataState.selectors.getCarriers],
  createOptionsMapper({
    labelKey: (item) => `${item.carrier_name} (${item.carrier_scac})`,
    valueKey: "carrier_id",
  }),
);

export const selectExceptionTypeFilterOptions = createSelector(
  [VinViewDomainDataState.selectors.selectExceptionTypesAlphabetically],
  createOptionsMapper({ labelKey: "name", valueKey: "id" }),
);

export const selectProductTypeFilterOptions = createSelector(
  [VinViewDomainDataState.selectors.getProductTypes],
  valuesMapper,
);

export const selectLifeCycleStateFilterOptions = createSelector(
  [VinViewDomainDataState.selectors.getLifeCycleStates],
  valuesMapper,
);
export const selectCurrentPositionTypes = createSelector(
  [VinViewDomainDataState.selectors.getCurrentPositionTypes],
  createOptionsMapper({
    labelKey: (item) => `${item.label}`,
    valueKey: (item) => `${item.value}`,
  }),
);

export const selectOrderTypeFilterOptions = createSelector(
  [VinViewDomainDataState.selectors.getOrderTypes],
  valuesMapper,
);

export const selectCustomerProvidedExteriorColor = createSelector(
  [VinViewDomainDataState.selectors.getCustomerProvidedExteriorColor],
  valuesMapper,
);

export const selectCustomerProvidedInteriorColor = createSelector(
  [VinViewDomainDataState.selectors.getCustomerProvidedInteriorColor],
  valuesMapper,
);

export const selectCustomerProvidedDoorType = createSelector(
  [VinViewDomainDataState.selectors.getCustomerProvidedDoorType],
  valuesMapper,
);

export const selectCustomerProvidedPowertrain = createSelector(
  [VinViewDomainDataState.selectors.getCustomerProvidedPowertrain],
  valuesMapper,
);
