import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import VinViewSearchBarState from "../../redux/VinViewSearchBarState";
import { FILTERS } from "./VinView.searchOptions";
import {
  selectCarrierFilterOptions,
  selectExceptionTypeFilterOptions,
  selectProductTypeFilterOptions,
  selectLifeCycleStateFilterOptions,
  selectCurrentPositionTypes,
  selectOrderTypeFilterOptions,
  selectCustomerProvidedExteriorColor,
  selectCustomerProvidedInteriorColor,
  selectCustomerProvidedDoorType,
  selectCustomerProvidedPowertrain,
} from "./VinView.SearchFilterSelectors";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthorization(state),
  carrierFilterOptions: selectCarrierFilterOptions(state),
  exceptionTypeFilterOptions: selectExceptionTypeFilterOptions(state),
  productTypeFilterOptions: selectProductTypeFilterOptions(state),
  lifeCycleStateFilterOptions: selectLifeCycleStateFilterOptions(state),
  searchFilters: VinViewSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    VinViewSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  areAllPrerequisiteFiltersSelected:
    VinViewSearchBarState.selectors.getAreAllPrerequisiteFiltersSelected(state),
  solutionId: getSolutionId(state),
  canUserSearch: VinViewSearchBarState.selectors.getCanUserSearch(state),
  currentPositionTypes: selectCurrentPositionTypes(state),
  orderTypeFilterOptions: selectOrderTypeFilterOptions(state),
  customerProvidedExteriorColorFilterOptions:
    selectCustomerProvidedExteriorColor(state),
  customerProvidedInteriorColorFilterOptions:
    selectCustomerProvidedInteriorColor(state),
  customerProvidedDoorTypeFilterOptions: selectCustomerProvidedDoorType(state),
  customerProvidedPowertrainFilterOptions:
    selectCustomerProvidedPowertrain(state),
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = VinViewSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
