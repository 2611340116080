import { getCategorySearchRequestBody } from "components/search-bar/open-search-filter-request-builder";
import { Features } from "modules/auth/Authorization";

export const SEARCH_CATEGORIES_OPEN_SEARCH = [
  {
    queryKey: "vinNumber",
    label: (t) => t("fv-vin-search:VIN"),
    placeholder: (t) => t("fv-vin-search:Search VIN"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: "vins",
  },
  {
    queryKey: "shipmentId",
    label: (t) => t("fv-vin-search:Shipment ID"),
    placeholder: (t) => t("fv-vin-search:Search Shipment ID"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: "shipments",
  },
  {
    queryKey: "orderNumber",
    label: (t) => t("fv-vin-search:Order #"),
    placeholder: (t) => t("fv-vin-search:Search Order #"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: "orderNumbers",
  },
  {
    // H2-305 productType is now descriptionSearch for the search bar
    queryKey: "productType",
    label: (t) => t("fv-vin-search:Product Type"),
    placeholder: (t) => t("fv-vin-search:Search Product Type"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: "productTypes",
  },
  {
    queryKey: "location",
    label: (t) => t("fv-vin-search:Location"),
    placeholder: (t) => t("fv-vin-search:Search Location"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: "locations",
  },
  {
    queryKey: "lastMilestone",
    label: (t) => t("fv-vin-search:Last Milestone"),
    placeholder: (t) => t("fv-vin-search:Search Last Milestone"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: null,
  },
  {
    queryKey: "itssId",
    label: (t) => t("fv-vin-search:ITSS ID"),
    placeholder: (t) => t("fv-vin-search:ITSS ID"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: null,
    requiredFeatures: [Features.ITSS],
  },
  {
    queryKey: "routeId",
    label: (t) => t("fv-vin-search:Route ID"),
    placeholder: (t) => t("fv-vin-search:Search Route ID"),
    transformFilterValue: (queryParameter, filterValue) => {
      return getCategorySearchRequestBody(queryParameter, filterValue);
    },
    property: "routeIds",
  },
];
