/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { MediaQueries } from "components/responsive";
import { useSetTitle } from "components/hooks/useSetTitle";
import { Dashboard } from "components/templates/Dashboard.template";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import SearchBarContainer from "pages/partview/components/search/PartView.SearchBar.container";
import FiltersContainer from "pages/partview/components/search/PartView.SearchFilters.container";
import { usePartViewExceptionLabel } from "pages/partview/components/hooks/usePartViewExceptionLabel";
import WatchedPackages from "pages/partview/dashboard/components/organisms/PartView.WatchedPackages.organism";
import { PartViewSavedSearchesPanel } from "pages/partview/dashboard/components/organisms/PartView.SavedSearchesPanel.organism";
import { getExceptionsDataForWidget } from "pages/partview/utils/exceptions.utils";
import { PackageStatusOption } from "pages/partview/utils/filter.utils";
import { partViewPackageStatusOnClick } from "pages/partview/shared/PartViewPackageStatusOnClick";
import { getFormattedLifecycleObject } from "pages/partview/utils/lifecycleState.utils";
import { PackageStatusCount } from "pages/partview/components/molecules/PackageStatusCount";

export const PartViewDashboard = ({
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
  resetSearchBar,
  exceptionsListRequestData = {},
  exceptionOrPackageStatusCountsRequestData = {},
  fetchExceptionList,
  fetchExceptionOrPackageStatusCounts,
  fetchWatchedPackages,
  watchedTableIsLoading,
  watchedTableResults,
  watchedTablePageIndex,
  watchedTablePageSize,
  watchedTablePageCount,
  setWatchedTablePageIndex,
  includeAPU,
}) => {
  const { t } = useTranslation("partview-dashboard");
  const { getTranslatedExceptionNameByCode } = usePartViewExceptionLabel();
  const [showFilters, setShowFilters] = useState(false);

  useSetTitle(t("partview-dashboard:PartView"));

  useTrackWithMixpanelOnce("Viewed Page: PartView / OEM Dashboard");

  useEffect(() => {
    resetSearchBar();
    clearSearchFilters();
  }, [resetSearchBar, clearSearchFilters]);

  useEffect(() => {
    fetchExceptionList();
    fetchExceptionOrPackageStatusCounts();
  }, [includeAPU, fetchExceptionList, fetchExceptionOrPackageStatusCounts]);
  const selectedActiveFilterOptions = useMemo(() => {
    let result = [
      PackageStatusOption.CREATED_OR_PACKAGED,
      PackageStatusOption.IN_ROUTE,
      PackageStatusOption.DELAYED,
    ];

    if (includeAPU) {
      result.push(PackageStatusOption.AVAILABLE_FOR_PICKUP);
    }
    return result;
  }, [includeAPU]);

  const onActiveGraphClick = useCallback(() => {
    clearSearchFilters();
    setSearchFilter("lifecycleState", selectedActiveFilterOptions);
    searchEntities();
  }, [
    clearSearchFilters,
    setSearchFilter,
    searchEntities,
    selectedActiveFilterOptions,
  ]);

  const onExceptionCountClick = useCallback(
    (exception) => {
      clearSearchFilters();

      if (exception.reasonCode === "Delivered") {
        setSearchFilter("lifecycleState", [PackageStatusOption.DELIVERED]);
      } else {
        setSearchFilter("lifecycleState", selectedActiveFilterOptions);
        setSearchFilter("exception", [
          { label: exception.name, value: exception.reasonCode },
        ]);
      }

      searchEntities();
    },
    [
      clearSearchFilters,
      setSearchFilter,
      searchEntities,
      selectedActiveFilterOptions,
    ],
  );

  const lifecycleStatusObject = getFormattedLifecycleObject(
    exceptionOrPackageStatusCountsRequestData?.data?.lifecycleStateCounts,
    t,
  );

  const lifecycleStatusAndExceptionLoading =
    exceptionOrPackageStatusCountsRequestData.isLoading;

  let totalActivePackages = lifecycleStatusObject[0]?.totalActivePackages;

  //Part view for part user
  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(show) => setShowFilters(show)}
    >
      <Dashboard.Tabs>
        <Dashboard.TabList>
          <Dashboard.Tab>{t("partview-dashboard:Summary View")}</Dashboard.Tab>
          <Dashboard.Tab>
            {t("partview-dashboard:My PartView Homepage")}
          </Dashboard.Tab>
        </Dashboard.TabList>
        <Dashboard.TabPanel
          styles={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            [MediaQueries.mediumAndUp]: {
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
            },
          }}
        >
          <div
            css={{
              [MediaQueries.mediumAndUp]: {
                gridColumnStart: 1,
                gridColumnEnd: -1,
              },
            }}
          >
            <ExceptionsPanel
              title={t("partview-dashboard:Package Exceptions")}
              totalCount={totalActivePackages ?? 0}
              totalCountIsLoading={
                exceptionOrPackageStatusCountsRequestData.isLoading
              }
              totalCountLabel={t("partview-dashboard:Active")}
              exceptionGroups={[
                {
                  title: t("partview-dashboard:Exceptions"),
                  includeInDonutChart: true,
                  style: { flexGrow: 2 },
                  exceptions: getExceptionsDataForWidget(
                    exceptionsListRequestData.data?.data,
                    exceptionOrPackageStatusCountsRequestData?.data
                      ?.exceptionCounts,
                    totalActivePackages,
                  ).map((exception) => ({
                    ...exception,
                    name: getTranslatedExceptionNameByCode(
                      exception.reasonCode,
                    ),
                  })),
                  isLoading:
                    exceptionsListRequestData.isLoading ||
                    exceptionOrPackageStatusCountsRequestData.isLoading,
                },
              ]}
              handleClickGraph={onActiveGraphClick}
              handleClickException={onExceptionCountClick}
              showNumberSeparator={true}
            />
          </div>
          <div
            css={{
              [MediaQueries.mediumAndUp]: {
                gridColumn: "1 / span 3",
                gridRow: "2/span 1",
              },
              [MediaQueries.extraLarge]: {
                gridColumn: "1 / span 3",
                gridRow: "2/span 1",
              },
            }}
          >
            <PackageStatusCount
              headerTitle={t("partview-dashboard:Package Status")}
              data={lifecycleStatusObject}
              isLoading={lifecycleStatusAndExceptionLoading}
              subTitle={t("partview-dashboard:All Packages")}
              title={t("partview-dashboard:Package Status")}
              handleCountClick={(_ignoredQueryKey, filterValue) =>
                partViewPackageStatusOnClick(
                  _ignoredQueryKey,
                  filterValue,
                  clearSearchFilters,
                  setSearchFilter,
                  searchEntities,
                )
              }
              queryKey="lifecyleState"
            />
          </div>
        </Dashboard.TabPanel>
        <Dashboard.TabPanel
          styles={{ display: "flex", flexDirection: "column", gap: "1em" }}
        >
          <PartViewSavedSearchesPanel />

          <WatchedPackages
            fetchWatchedPackages={fetchWatchedPackages}
            isLoading={watchedTableIsLoading}
            watchedPackages={watchedTableResults}
            pageIndex={watchedTablePageIndex}
            pageSize={watchedTablePageSize}
            pageCount={watchedTablePageCount}
            setPageIndex={setWatchedTablePageIndex}
          />
        </Dashboard.TabPanel>
      </Dashboard.Tabs>
    </Dashboard>
  );
};

PartViewDashboard.propTypes = {
  searchEntities: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  fetchExceptionList: PropTypes.func,
  fetchExceptionOrPackageStatusCounts: PropTypes.func,
  exceptionsListRequestData: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string, // name
          value: PropTypes.string, // reasonCode
        }),
      ),
    }),
    isLoading: PropTypes.bool,
  }),
  exceptionOrPackageStatusCountsRequestData: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.number,
      delivered: PropTypes.number,
      exceptions: PropTypes.objectOf(PropTypes.number),
    }),
    isLoading: PropTypes.bool,
  }),
  fetchWatchedPackages: PropTypes.func.isRequired,
  watchedTableIsLoading: PropTypes.bool,
  watchedTableResults: PropTypes.array,
  watchedTablePageIndex: PropTypes.number,
  watchedTablePageSize: PropTypes.number,
  watchedTablePageCount: PropTypes.number,
  setWatchedTablePageIndex: PropTypes.func.isRequired,
  includeAPU: PropTypes.bool,
};
