import { connect } from "react-redux";
import { SearchBar } from "components/search-bar/SearchBar";
import SearchBarStateOpenSearch from "pages/finishedvehicle/redux/FinVehicleSearchBarStateOpenSearch";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import finVehicleDomainData from "modules/domain-data/FinVehicleDomainData";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getCanUserSearch,
    getTotalEntities,
  } = SearchBarStateOpenSearch.selectors;

  return {
    auth: getAuthorization(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    canUserSearch: getCanUserSearch(state),
    solutionId: getSolutionId(state),
    exceptionTypes: finVehicleDomainData.selectors.getExceptionTypes(state),
    totalCount: getTotalEntities(state),
    vins: finVehicleDomainData.selectors.getVINs(state),
    shipments: finVehicleDomainData.selectors.getShipments(state),
    orderNumbers: finVehicleDomainData.selectors.getOrderNumbers(state),
    productTypes:
      finVehicleDomainData.selectors.getProductTypesOpenSearch(state),
    locations: finVehicleDomainData.selectors.getLocationOpenSearch(state),
    lastMilestone:
      finVehicleDomainData.selectors.getLastMilestoneOpenSearch(state),
    itssId: finVehicleDomainData.selectors.getItssIdOpenSearch(state),
    routeIds: finVehicleDomainData.selectors.getRouteIdOpenSearch(state),
  };
};

const {
  setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
} = SearchBarStateOpenSearch.actionCreators;
const { fetchDomainData } = finVehicleDomainData.actionCreators;

const actionCreators = {
  setSearchCategory: setSearchCategoryForKey,
  setSearchText: (text, ignoreValueChange) => (dispatch) => {
    dispatch(setSearchText(text, ignoreValueChange, true));
  },
  clearSearchText,
  resetSearchBar,
  searchEntities,
  clearSavedSearch,
  fetchDomainData,
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
