import { PackageStatusOption } from "pages/partview/utils/filter.utils";

export const partViewPackageStatusOnClick = (
  _ignoredQueryKey,
  filterValue,
  clearSearchFilters,
  setSearchFilter,
  searchEntities,
) => {
  clearSearchFilters();

  if (filterValue === "Created") {
    setSearchFilter("lifecycleState", [
      PackageStatusOption.CREATED_OR_PACKAGED,
    ]);
  }

  if (filterValue === "In Route") {
    setSearchFilter("lifecycleState", [PackageStatusOption.IN_ROUTE]);
  }

  if (filterValue === "Delayed") {
    setSearchFilter("lifecycleState", [PackageStatusOption.DELAYED]);
  }

  if (filterValue === "AvailableForPickUp") {
    setSearchFilter("lifecycleState", [
      PackageStatusOption.AVAILABLE_FOR_PICKUP,
    ]);
  }

  if (filterValue === "Delivered") {
    setSearchFilter("lifecycleState", [PackageStatusOption.DELIVERED]);
  }

  searchEntities();
};
