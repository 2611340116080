/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Search } from "components/templates/Search.template";
import SearchBarContainer from "pages/partview/components/search/DealerPartView.SearchBar.container";
import FiltersContainer from "pages/partview/components/search/DealerPartView.SearchFilters.container";
import DealerPartViewEditSavedSearchModalContainer from "pages/partview/components/search/DealerPartView.EditSavedSearchModal.container";
import {
  useColumns,
  WATCH_COLUMN_ID,
} from "pages/partview/search/PartView.Search.columns";

export const DealerPartViewSearch = (props) => {
  const {
    savedSearch,
    loadSavedSearch,
    toggleShowFilters,
    pushPartViewDetailsPage,
    dealerPartViewSearchResults,
    isLoading,
    showFilters,
    sortColumn,
    reverseSort,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    resetSearch,
    totalEntities,
    totalEntitiesIsLoading,
    exportEntities,
    isExporting,
    exportIdentifier,
    exportName,
    exportFailed,
    clearExportErrors,
    resetExport,
    selectedFvOrgId,
    isDealerOrg,
    loadingError,
  } = props;
  const { t } = useTranslation("partview-search");

  useSetTitleOnMount(t("partview-search:Search Results"));

  useTrackWithMixpanelOnce("Viewed Page: PartView / Dealer Search");

  let timeOutMessage =
    loadingError?.response?.status === 408
      ? t(
          "partview-search:The Search results have timed out, please retry your search again.",
        )
      : null;

  // check for timeout and set the updated partView search results for the search result page
  let updatedPvSearchResults =
    loadingError?.response?.status === 408 ? [] : dealerPartViewSearchResults;

  const rowClickHandler = (row, cell) => {
    // Prevent navigation if clicking in "watch" checkbox cell.
    if (cell.column.id === WATCH_COLUMN_ID) {
      return;
    }

    // Navigate to Package Details when clicking row
    pushPartViewDetailsPage(row.original.trackingNumber);
  };

  const columns = useColumns({ isPartSeller: true });

  return (
    <Search
      isLoading={isLoading}
      searchResults={updatedPvSearchResults}
      hideSearchResults={!isDealerOrg && _.isNil(selectedFvOrgId)}
      hideSearchResultsMessage={t(
        "vinview-search:Select a Dealer Organization from the filter above to continue.",
      )}
      totalCount={totalEntities}
      totalCountIsLoading={totalEntitiesIsLoading}
      totalCountTitle={t("partview-search:Total Packages")}
      savedSearch={savedSearch}
      loadSavedSearch={loadSavedSearch}
      SavedSearchModalContainer={DealerPartViewEditSavedSearchModalContainer}
      resetSearch={resetSearch}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      exportProps={{
        exportEntities: exportEntities,
        exportIdentifier: exportIdentifier,
        exportName: exportName,
        isExporting: isExporting,
        exportFailed: exportFailed,
        clearExportErrors: clearExportErrors,
        resetExport: resetExport,
      }}
      tableProps={{
        data: dealerPartViewSearchResults,
        columns: columns,
        isLoading: isLoading,
        rowClickHandler: rowClickHandler,
        enablePageJump: false,
        showPagination: true,
        fixPaginationToBottom: true,
        isManualPagination: true,
        isManualSorting: true,
        sortColumn: sortColumn,
        reverseSort: reverseSort,
        pageIndex: page,
        pageSize: pageSize,
        pageCount: totalPages,
        noDataFoundMessage: timeOutMessage,
        onPageChange: (newPage) => {
          setPagination(newPage, pageSize);
        },
        onPageSizeChange: (newPageSize) => {
          setPagination(0, newPageSize);
        },
        onPageSort: (column, isReverse) => {
          setSort(column, isReverse);
        },
      }}
    />
  );
};

DealerPartViewSearch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadSavedSearch: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pushPartViewDetailsPage: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  exportEntities: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  totalEntities: PropTypes.number.isRequired,
  totalEntitiesIsLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  dealerPartViewSearchResults: PropTypes.array.isRequired,
  isExporting: PropTypes.bool.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  resetExport: PropTypes.func,
  selectedFvOrgId: PropTypes.string,
  isDealerOrg: PropTypes.bool,
  loadingError: {
    response: {
      status: PropTypes.number,
    },
  },
};
