/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { MediaQueries } from "components/responsive";
import { useSetTitle } from "components/hooks/useSetTitle";
import { Dashboard } from "components/templates/Dashboard.template";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import SearchBarContainer from "pages/partview/components/search/DealerPartView.SearchBar.container";
import FiltersContainer from "pages/partview/components/search/DealerPartView.SearchFilters.container";
import WatchedPackages from "pages/partview/dashboard/components/organisms/PartView.WatchedPackages.organism";
import { DealerPartViewSavedSearchesPanel } from "pages/partview/dashboard/components/organisms/DealerPartView.SavedSearchesPanel.organism";
import { PartViewEtaWidget } from "pages/partview/dashboard/components/PartViewEtaWidget";
import { PackageStatusCount } from "pages/partview/components/molecules/PackageStatusCount";
import { getFormattedLifecycleObject } from "pages/partview/utils/lifecycleState.utils";
import { PackageStatusOption } from "pages/partview/utils/filter.utils";
import { HalfPieChartAndTable } from "shared/components/organisms/HalfPieChartAndTable.organism";
import { partViewPackageStatusOnClick } from "pages/partview/shared/PartViewPackageStatusOnClick";
import { useFormattedExceptionCount } from "pages/partview/utils/exceptions.utils";
import { getExceptionChartColor } from "pages/partview/utils/exceptions.utils";

export const DealerPartViewDashboard = ({
  searchEntities,
  setSearchFilter,
  clearSearchFilters,
  resetSearchBar,
  selectedFvOrgId,
  exceptionOrPackageStatusCountsRequestData = {},
  activeAndExceptionCountsRequestData = {},
  fetchExceptionOrPackageStatusCounts,
  fetchActiveAndExceptionCounts,
  fetchWatchedPackages,
  watchedTableIsLoading,
  watchedTableResults,
  watchedTablePageIndex,
  watchedTablePageSize,
  watchedTablePageCount,
  setWatchedTablePageIndex,
  isDealerOrg,
  etaPackagesCountsRequestData,
  fetchEtaPackagesCounts,
  includeAPU,
  resetSavedSearch,
}) => {
  const { t } = useTranslation("partview-dashboard");
  const [showFilters, setShowFilters] = useState(!isDealerOrg);

  useSetTitle(t("partview-dashboard:PartView"));

  useTrackWithMixpanelOnce("Viewed Page: PartView / Dealer Dashboard");

  const canFetchData =
    isDealerOrg || (!isDealerOrg && !_.isNil(selectedFvOrgId));

  useEffect(() => {
    resetSearchBar();
    clearSearchFilters();
  }, [resetSearchBar, clearSearchFilters]);

  useEffect(() => {
    if (canFetchData) {
      fetchExceptionOrPackageStatusCounts(selectedFvOrgId);
      fetchActiveAndExceptionCounts(selectedFvOrgId, includeAPU);
      fetchWatchedPackages(selectedFvOrgId);
      fetchEtaPackagesCounts(selectedFvOrgId);
      resetSavedSearch();
    }
  }, [
    includeAPU,
    canFetchData,
    selectedFvOrgId,
    fetchExceptionOrPackageStatusCounts,
    fetchActiveAndExceptionCounts,
    fetchWatchedPackages,
    fetchEtaPackagesCounts,
    resetSavedSearch,
  ]);
  let packageExceptions = useFormattedExceptionCount(
    exceptionOrPackageStatusCountsRequestData?.data?.exceptionCounts,
  );

  const lifecycleStatusObject = getFormattedLifecycleObject(
    exceptionOrPackageStatusCountsRequestData?.data?.lifecycleStateCounts,
    t,
  );
  const lifecycleStatusAndExceptionLoading =
    exceptionOrPackageStatusCountsRequestData.isLoading;

  const activeAndExceptionCountsLoading =
    activeAndExceptionCountsRequestData.isLoading;

  const onEtaCountClick = useCallback(
    (row) => {
      if (row) {
        clearSearchFilters();
        setSearchFilter("lifecycleState", [PackageStatusOption.IN_ROUTE]);
        const date = row.original.date;
        let updatedDate;
        if (date.includes(">")) {
          //date='>{the actual date}', remove the 1st character of the string to get the date
          updatedDate = date.substr(1);
          setSearchFilter("deliveryDate", {
            from: moment(updatedDate).add(24, "hours"),
            to: null,
            dateType: ["eta"],
          });
        } else {
          updatedDate = date;
          setSearchFilter("deliveryDate", {
            from: moment(updatedDate),
            to: moment(updatedDate).add(24, "hours"),
            dateType: ["eta"],
          });
        }

        searchEntities();
      }
    },
    [clearSearchFilters, setSearchFilter, searchEntities],
  );

  const dataForExceptionWidget = (packageExceptions) => {
    return packageExceptions.map((exception) => {
      return {
        ...exception,
        // Generating the full display text here to be used wherever it is needed
        fullDescription: `${exception.type} (${exception.reasonCode})`,
        fill: getExceptionChartColor(exception.reasonCode),
      };
    });
  };

  const handleExceptionRowClick = (row) => {
    if (row) {
      const exceptionType = row?.original?.type;
      const exceptionTypeReasonCode = row?.original?.reasonCode;
      clearSearchFilters();
      setSearchFilter("exception", [
        { label: exceptionType, value: exceptionTypeReasonCode },
      ]);
      searchEntities();
    }
  };

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(show) => setShowFilters(show)}
    >
      {!isDealerOrg && _.isNil(selectedFvOrgId) ? (
        <Alert variant={AlertVariant.Warning} show>
          {t(
            "partview-dashboard:Select a Dealer Organization from the filter above to continue.",
          )}
        </Alert>
      ) : null}
      {canFetchData ? (
        <Dashboard.Tabs>
          <Dashboard.TabList>
            <Dashboard.Tab>
              {t("partview-dashboard:Summary View")}
            </Dashboard.Tab>
            <Dashboard.Tab>
              {t("partview-dashboard:My PartView Homepage")}
            </Dashboard.Tab>
          </Dashboard.TabList>

          <Dashboard.TabPanel
            styles={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              [MediaQueries.mediumAndUp]: {
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              },
            }}
          >
            <div
              css={{
                [MediaQueries.mediumAndUp]: {
                  gridColumn: "1 / span 3",
                  gridRow: "1/span 1",
                },
                [MediaQueries.extraLarge]: {
                  gridColumn: "1 / span 3",
                  gridRow: "1/span 1",
                },
              }}
            >
              <PackageStatusCount
                headerTitle={t("partview-dashboard:Package Status")}
                data={lifecycleStatusObject}
                isLoading={lifecycleStatusAndExceptionLoading}
                subTitle={t("partview-dashboard:All Packages")}
                title={t("partview-dashboard:Package Status")}
                handleCountClick={(_ignoredQueryKey, filterValue) =>
                  partViewPackageStatusOnClick(
                    _ignoredQueryKey,
                    filterValue,
                    clearSearchFilters,
                    setSearchFilter,
                    searchEntities,
                  )
                }
                queryKey="lifecyleState"
              />
            </div>
            <div
              css={{
                [MediaQueries.mediumAndUp]: {
                  gridColumnStart: 4,
                  gridColumnEnd: -1,
                  gridRow: "1 / span 2",
                },
                [MediaQueries.extraLarge]: {
                  gridColumn: 4,
                  gridColumnEnd: -1,
                  gridRow: "1 / span 2",
                },
              }}
            >
              <HalfPieChartAndTable
                title={t("partview-dashboard:Active Exceptions Overview")}
                isLoading={activeAndExceptionCountsLoading}
                nameAndCountList={dataForExceptionWidget(packageExceptions)}
                dataLabel={t("partview-dashboard:Exceptions")}
                tableHeaderDataTypeLabel={t(
                  "partview-dashboard:Exception Type",
                )}
                tableHeaderDataCountLabel={t("partview-dashboard:Packages")}
                chartDataLabel={t("partview-dashboard:Packages")}
                showTotalCount={false}
                onRowClick={handleExceptionRowClick}
                width={50}
              />
            </div>
            <div
              css={{
                [MediaQueries.mediumAndUp]: {
                  gridColumnStart: 1,
                  gridColumnEnd: -1,
                },
                [MediaQueries.extraLarge]: {
                  gridColumn: "1 / span 3",
                  gridRow: "2/ span 3",
                },
              }}
            >
              <PartViewEtaWidget
                isLoading={etaPackagesCountsRequestData?.isLoading}
                data={etaPackagesCountsRequestData?.data?.data?.packages}
                handleEtaClick={onEtaCountClick}
              />
            </div>
          </Dashboard.TabPanel>
          <Dashboard.TabPanel
            styles={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              [MediaQueries.mediumAndUp]: {
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
              },
            }}
          >
            <div
              css={{
                [MediaQueries.mediumAndUp]: {
                  gridColumnStart: 1,
                  gridColumnEnd: -1,
                },
              }}
            >
              <WatchedPackages
                fetchWatchedPackages={fetchWatchedPackages}
                isLoading={watchedTableIsLoading}
                watchedPackages={watchedTableResults}
                pageIndex={watchedTablePageIndex}
                pageSize={watchedTablePageSize}
                pageCount={watchedTablePageCount}
                setPageIndex={setWatchedTablePageIndex}
                isPartSeller={true}
                selectedFvOrgId={selectedFvOrgId}
                isDealerOrg={isDealerOrg}
                hasDealerView={true}
              />
            </div>
            <div
              css={{
                [MediaQueries.mediumAndUp]: {
                  gridColumnStart: 1,
                  gridColumnEnd: -1,
                },
              }}
            >
              <DealerPartViewSavedSearchesPanel
                isDealerOrg={isDealerOrg}
                selectedFvOrgId={selectedFvOrgId}
              />
            </div>
          </Dashboard.TabPanel>
        </Dashboard.Tabs>
      ) : null}
    </Dashboard>
  );
};

DealerPartViewDashboard.propTypes = {
  searchEntities: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  fetchExceptionOrPackageStatusCounts: PropTypes.func,
  fetchActiveAndExceptionCounts: PropTypes.func,
  fetchWatchedPackages: PropTypes.func.isRequired,
  watchedTableIsLoading: PropTypes.bool,
  watchedTableResults: PropTypes.array,
  watchedTablePageIndex: PropTypes.number,
  watchedTablePageSize: PropTypes.number,
  watchedTablePageCount: PropTypes.number,
  setWatchedTablePageIndex: PropTypes.func.isRequired,
  exceptionOrPackageStatusCountsRequestData: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.number,
      delivered: PropTypes.number,
      exceptions: PropTypes.objectOf(PropTypes.number),
    }),
    isLoading: PropTypes.bool,
  }),
  activeAndExceptionCountsRequestData: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.number,
      delivered: PropTypes.number,
      exceptions: PropTypes.objectOf(PropTypes.number),
    }),
    isLoading: PropTypes.bool,
  }),
  selectedFvOrgId: PropTypes.string,
  isDealerOrg: PropTypes.bool,
  etaPackagesCountsRequestData: PropTypes.object,
  fetchEtaPackagesCounts: PropTypes.func.isRequired,
  includeAPU: PropTypes.bool,
  resetSavedSearch: PropTypes.func.isRequired,
};
