import _ from "lodash";
import axios from "axios";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";
import apiUrl from "../../api-url";
import { alfBy } from "../../components/selectors";
import { OrganizationType } from "shared/constants/organization.const";

// Exported URLs
export const currentPositionCodesUrl = (dealerOrgId) => {
  let url = `/entity-search/list?currentPositionCodes=1`;
  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const productTypesUrl = (dealerOrgId) => {
  let url = `/entity-search/list?productType=1`;
  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const locationsUrl = (dealerOrgId) => {
  let url = `/entity-search/list?vinviewLocations=1`;
  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const lastMilestoneUrl = (dealerOrgId) => {
  // update API link
  let url = `/entity-search/list?lastMilestone=1`;
  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const soldToUrl = (dealerOrgId) => {
  let url = `/entity-search/list?ref:soldToDealer=1`;

  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const finCodeUrl = (dealerOrgId) => {
  let url = `/entity-search/list?ref:FinCode=1`;

  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const customerprovidedExteriorColorUrl = (dealerOrgId) => {
  let url = `/entity-search/list?ref:CustomerProvided-ExteriorColor=1`;

  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const customerprovidedInteriorColorUrl = (dealerOrgId) => {
  let url = `/entity-search/list?ref:CustomerProvided-InteriorColor=1`;

  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const customerprovidedDoorTypeUrl = (dealerOrgId) => {
  let url = `/entity-search/list?ref:CustomerProvided-DoorType=1`;

  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const customerprovidedPowertrainUrl = (dealerOrgId) => {
  let url = `/entity-search/list?ref:CustomerProvided-Powertrain=1`;

  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const endUserFinCodeUrl = (dealerOrgId) => {
  let url = `/entity-search/list?ref:EndUserFinCode=1`;

  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

export const originUrl = (dealerOrgId) => {
  let url = `/entity-search/list?vinviewOriginLocations=1`;
  if (dealerOrgId) {
    url += `&dealerOrgId=${dealerOrgId}`;
  }
  return apiUrl(url);
};

const STORE_MOUNT_POINT = "vinViewDomainData";
const vinViewDomainDuck = buildFetchDuck("vinViewDomainDuck");
const vinViewProductTypeDuck = buildFetchDuck("vinViewProductTypeDuck");
const vinViewPositionTypesDuck = buildFetchDuck("vinViewPositionTypesDuck");
const vinViewOrderTypeDuck = buildFetchDuck("vinViewOrderTypeDuck");
const vinViewOrderNumberDuck = buildFetchDuck("vinViewOrderNumberDuck");
const vinViewExceptionTypesDuck = buildFetchDuck("vinViewExceptionTypesDuck");
const vinViewCustomerProvidedExteriorColorDuck = buildFetchDuck(
  "vinViewCustomerProvidedExteriorColorDuck",
);
const vinViewCustomerProvidedInteriorColorDuck = buildFetchDuck(
  "vinViewCustomerProvidedInteriorColorDuck",
);
const vinViewCustomerProvidedDoorTypeDuck = buildFetchDuck(
  "vinViewCustomerProvidedDoorTypeDuck",
);
const vinViewCustomerProvidedPowertrainDuck = buildFetchDuck(
  "vinViewCustomerProvidedPowertrainDuck",
);

export function fetchDomainData(dealerOrgId = null) {
  const url = apiUrl(`/entity-search/list`);

  const domainDataParams = {
    carrier: 1,
    // H1-1549: for some reason they use the parameter here as lifecycleState
    // but return this as lifeCycleState, with a capital c, so, this is the only
    // place where whe have this written in this way
    lifecycleState: 1,
  };
  const domainDataCurrentPositionTypesParams = {
    currentPositionTypes: 1,
  };
  const domainDataProductTypeParams = {
    productType: 1,
  };
  const domainDataOrderTypeParams = {
    "ref:OrderType": 1,
  };
  const domainDataOrderNumberParams = {
    "ref:OrderNumber": 1,
  };
  const domainDataExceptionTypeParams = {
    exceptionType: 1,
  };

  const domainDataCustomerProvidedExteriorColorParams = {
    "ref:CustomerProvided-ExteriorColor": 1,
  };
  const domainDataCustomerProvidedInteriorColorParams = {
    "ref:CustomerProvided-InteriorColor": 1,
  };
  const domainDataCustomerProvidedDoorTypeParams = {
    "ref:CustomerProvided-DoorType": 1,
  };
  const domainDataCustomerProvidedPowertrainParams = {
    "ref:CustomerProvided-Powertrain": 1,
  };

  if (dealerOrgId) {
    domainDataParams.dealerOrgId = dealerOrgId;
    domainDataCurrentPositionTypesParams.dealerOrgId = dealerOrgId;
    domainDataProductTypeParams.dealerOrgId = dealerOrgId;
    domainDataOrderTypeParams.dealerOrgId = dealerOrgId;
    domainDataOrderNumberParams.dealerOrgId = dealerOrgId;
    domainDataExceptionTypeParams.dealerOrgId = dealerOrgId;
    domainDataCustomerProvidedExteriorColorParams.dealerOrgId = dealerOrgId;
    domainDataCustomerProvidedInteriorColorParams.dealerOrgId = dealerOrgId;
    domainDataCustomerProvidedDoorTypeParams.dealerOrgId = dealerOrgId;
    domainDataCustomerProvidedPowertrainParams.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    dispatch(vinViewDomainDuck.fetch(url, { params: domainDataParams }));
    dispatch(
      vinViewProductTypeDuck.fetch(url, {
        params: domainDataProductTypeParams,
      }),
    );
    dispatch(
      vinViewPositionTypesDuck.fetch(url, {
        params: domainDataCurrentPositionTypesParams,
      }),
    );
    dispatch(
      vinViewOrderTypeDuck.fetch(url, { params: domainDataOrderTypeParams }),
    );
    dispatch(
      vinViewOrderNumberDuck.fetch(url, {
        params: domainDataOrderNumberParams,
      }),
    );
    dispatch(
      vinViewExceptionTypesDuck.fetch(url, {
        params: domainDataExceptionTypeParams,
      }),
    );
    dispatch(
      vinViewCustomerProvidedExteriorColorDuck.fetch(url, {
        params: domainDataCustomerProvidedExteriorColorParams,
      }),
    );
    dispatch(
      vinViewCustomerProvidedInteriorColorDuck.fetch(url, {
        params: domainDataCustomerProvidedInteriorColorParams,
      }),
    );
    dispatch(
      vinViewCustomerProvidedDoorTypeDuck.fetch(url, {
        params: domainDataCustomerProvidedDoorTypeParams,
      }),
    );
    dispatch(
      vinViewCustomerProvidedPowertrainDuck.fetch(url, {
        params: domainDataCustomerProvidedPowertrainParams,
      }),
    );
  };
}

export function fetchDealerOrganizations(query = "", loadedResults = []) {
  const url = apiUrl("/iam/organizations");

  const pageSize = 20;
  const nextPage = loadedResults.length / pageSize;

  const config = {
    params: {
      everything: query.length > 0 ? query : undefined,
      orgType: OrganizationType.DEALER,
      pageNumber: nextPage,
      pageSize,
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      const results = response.data?.response;

      return {
        hasMore: results.length === pageSize,
        options: results.map((org) => ({
          label: org.org_name,
          value: org.organization_id,
        })),
      };
    })
    .catch((error) => {
      console.error(error);
      return { hasMore: false, options: [] };
    });
}

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent].data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getExceptionTypes = createSelectorFromProperty(
  "exceptionTypes",
  "exceptionType",
);
const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");

// H2-366: Remove VINs from typeahead
const getVINs = (state) => [];

const getProductTypes = createSelectorFromProperty(
  "productTypes",
  "productTypes",
);

const getOrderTypes = createSelectorFromProperty(
  "ref:OrderType",
  "orderType",
  true,
);
const getOrderNumbers = createSelectorFromProperty(
  "ref:OrderNumber",
  "orderNumber",
  true,
);

const getCustomerProvidedExteriorColor = createSelectorFromProperty(
  "ref:CustomerProvided-ExteriorColor",
  "customerprovidedExteriorColor",
);

const getCustomerProvidedInteriorColor = createSelectorFromProperty(
  "ref:CustomerProvided-InteriorColor",
  "customerprovidedInteriorColor",
);

const getCustomerProvidedDoorType = createSelectorFromProperty(
  "ref:CustomerProvided-DoorType",
  "customerprovidedDoorType",
);

const getCustomerProvidedPowertrain = createSelectorFromProperty(
  "ref:CustomerProvided-Powertrain",
  "customerprovidedPowertrain",
);

const getLifeCycleStates = createSelectorFromProperty(
  "lifecycleState",
  "domainData",
);

const getCarriers = createSelectorFromProperty(
  "carriers",
  "domainData",
  "carrier_name",
);

const getCurrentPositionTypes = createSelectorFromProperty(
  "currentPositionTypes",
  "currentPositionTypes",
);

const getDomainDataLoading = (state) =>
  state[STORE_MOUNT_POINT].domainData.isLoading || false;

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getProductTypes,
    getLifeCycleStates,
    getVINs,
    getOrderTypes,
    getOrderNumbers,
    getCarriers,
    getDomainDataLoading,
    getCurrentPositionTypes,
    getCustomerProvidedExteriorColor,
    getCustomerProvidedInteriorColor,
    getCustomerProvidedDoorType,
    getCustomerProvidedPowertrain,
  },
  reducer: combineReducers({
    domainData: vinViewDomainDuck.reducer,
    productTypes: vinViewProductTypeDuck.reducer,
    currentPositionTypes: vinViewPositionTypesDuck.reducer,
    orderType: vinViewOrderTypeDuck.reducer,
    orderNumber: vinViewOrderNumberDuck.reducer,
    exceptionType: vinViewExceptionTypesDuck.reducer,
    customerprovidedExteriorColor:
      vinViewCustomerProvidedExteriorColorDuck.reducer,
    customerprovidedInteriorColor:
      vinViewCustomerProvidedInteriorColorDuck.reducer,
    customerprovidedDoorType: vinViewCustomerProvidedDoorTypeDuck.reducer,
    customerprovidedPowertrain: vinViewCustomerProvidedPowertrainDuck.reducer,
  }),
};
